import { User } from 'app/entity/infrastructure/user.model';

import { UserActions } from '../actions';

export interface State {
  active: User;
  impersonate: User;
}

export const initialState: State = {
  active: null,
  impersonate: null,
};

export const reducer = (state: State = initialState, action: UserActions.UserActionsUnion): State => {
  switch (action.type) {
    case UserActions.setUserProfile.type:
    case UserActions.setUser.type: {
      return {
        ...state,
        active: action.user,
      };
    }
    case UserActions.setUserOrg.type: {
      return {
        ...state,
        active: { ...state.active, defaultOrganization: action.organization },
      };
    }

    case UserActions.impersonate.type: {
      return {
        ...state,
        impersonate: action.user,
      };
    }

    default:
      return state;
  }
};

export const getUser = (state: State): User => state && state.impersonate ? state.impersonate : state.active;
export const getImpersonated = (state: State): User|null => state && state.impersonate ? state.impersonate : null;
export const getIsAdmin = (state: State): boolean => state && state.active ? state.active.admin : false;
