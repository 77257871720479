import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationService } from 'app/organization/services/organization';
import { map } from 'rxjs/operators';

import { UserActions } from '../actions';
import * as fromRoot from '../reducers';

@Injectable()
export class UserEffects {
  user$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.setUser.type),
    map((action: any) => action.user),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private organizationService: OrganizationService,
  ) {
  }
}
