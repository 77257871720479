import { ClusterNode } from 'app/entity/compute-kubernetes';
import { Status } from 'app/shared/services/messages/entity-connection';
import { MessageOptions } from 'app/shared/services/messages/handler/message.handler';
import { ToastrService } from 'ngx-toastr';

export class KubernetesNodeMessages implements MessageOptions<ClusterNode> {
  createMessageCallback(toastr: ToastrService, entity: ClusterNode): void {
    toastr.success(`<b>Node:</b> Succeeded to create ${entity.name} in cluster ${entity.cluster.name}`, '', { enableHtml: true });
  }

  updateMessageCallback(_toastr: ToastrService, _entity: ClusterNode): void {
  }

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void {
  }

  deleteMessageCallback(_toastr: ToastrService, _entity: ClusterNode): void {
  }
}
