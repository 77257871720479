<ng-container *ngIf="paginationLoaded">
  <myflow-table-card *ngIf="instanceTable && cardView; else table">
    <ng-container [ngTemplateOutlet]="table" table></ng-container>
    <ng-container [ngTemplateOutlet]="cards" cards></ng-container>
  </myflow-table-card>
</ng-container>
<myflow-table-placeholders *ngIf="!paginationLoaded" [columns]="columns.toArray()" [instanceTable]="instanceTable"></myflow-table-placeholders>

<ng-container *ngIf="loaded && empty && paginationLoaded">
  <ng-content select="[empty-table]"></ng-content>
</ng-container>

<myflow-table-pagination
  *ngIf="loaded && !disablePagination && !customToggle"
  
  [dataSource]="handlePagination ? dataSource : null"
  [size]="length ? length : dataSource.data.length"
  [limit]="limit"
  [page]="page"
  (changeState)="updateTableState($event)"
  (changedPage)="updatePageState($event)"
  (setLimit)="updateLimitState($event)"
  (loaded)="updatePaginationLoaded($event)"

  [showToggle]="instanceTable && cardView"
  [showBasedOnSize]="!handlePagination"
  leftToggle="List"
  rightToggle="Card"
  [toggleState]="!tableView"
  (toggled)="switchView($event)" 
/>
<myflow-table-pagination 
  *ngIf="customToggle"
  
  [dataSource]="handlePagination ? dataSource : null"
  [size]="length ? length : dataSource.data.length"
  [limit]="limit"
  [page]="page"
  (changeState)="updateTableState($event)"
  (changedPage)="updatePageState($event)"
  (setLimit)="updateLimitState($event)"
  (loaded)="updatePaginationLoaded($event)"

  [showToggle]="true"
  [showBasedOnSize]="!handlePagination"
  [leftToggle]="leftToggleLabel"
  [rightToggle]="rightToggleLabel"
  [toggleState]="customToggleState"
  (toggled)="onCustomToggleEvent($event)" 
/>

<ng-template #table>
  <div [ngClass]="{'instances-table': instanceTable}">
    <mat-table 
      class="mat-elevation-z8" 
      [ngClass]="{'instances': instanceTable}" 
      
      matSort
      multiTemplateDataRows
      
      [dataSource]="dataSource"
      [hidden]="!loaded || (instanceTable && empty)" 
      [matSortActive]="sortActiveColumn"         
      [matSortDirection]="sortActiveDirection" 
      [matSortDisabled]="sortDisabled"
    >
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.column"> 
        <ng-container *ngIf="!column.sortable">
          <mat-header-cell *matHeaderCellDef [ngClass]="column.width">
            <ng-container *ngIf="i === 0 && expandable">
              <i style="margin-right: 24px;"></i>
            </ng-container>
            {{column.title}}
          </mat-header-cell>
        </ng-container>
        <ng-container *ngIf="column.sortable">
          <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="column.width">
            <ng-container *ngIf="i === 0 && expandable">
              <i style="margin-right: 24px;"></i>
            </ng-container>
            {{column.title}}
          </mat-header-cell>
        </ng-container>

        <mat-cell *matCellDef="let item" [ngClass]="column.width">
          <ng-container *ngIf="i === 0 && expandable && isItemExpandable(item)">
            <i class="icon-angel-right" [ngClass]="{ 'open': expandedElement === item }"></i>
          </ng-container>
          <ng-container *ngIf="i === 0 && expandable && !isItemExpandable(item)">
            <i style="margin-right: 24px;"></i>
          </ng-container>
          <ng-container
            *ngTemplateOutlet="column.cellDef?.template; context: {$implicit: item, card: false}"></ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail" *ngIf="expandable">
          <mat-cell *matCellDef="let element" [attr.colspan]="columns.length" class="expandable-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <ng-container *ngIf="element === expandedElement">
              <ng-container *ngTemplateOutlet="collapse; context: {$implicit: element}"></ng-container>
            </ng-container>
          </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns" />
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'dummy': isDummyRecord(row), 'expandable-element-row': expandable }"
        [class.expandable-expanded-row]="expandedElement === row"
        (click)="expandElement(row)"
      />

      <ng-container *ngIf="expandable">
        <mat-row 
          *matRowDef="let row; columns: ['expandedDetail']" 
          [class.expanded]="expandedElement === row" 
          class="expandable-detail-row" 
        />
      </ng-container>
    </mat-table>

    <myflow-table-placeholders *ngIf="!loaded" [columns]="columns.toArray()" [instanceTable]="instanceTable" />
  </div>
</ng-template>

<ng-template #cards>
  <div class="instance-plates" [hidden]="!loaded || empty">
    <div *ngFor="let item of dataSource._pageData(dataSource.filteredData)">
      <div class="instance-plates-wrapper">
        <a [routerLink]="[item.id, 'details']" fragment="volumes" class="instance-plate" [title]="item.name">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title *ngIf="titleColumn">
                <ng-container
                  *ngTemplateOutlet="titleColumn.cellDef.template; context: {$implicit: item, card: true}"></ng-container>
              </mat-card-title>
              <div class="plate-header-content">
                <ng-container *ngFor="let column of headerColumns">
                  <ng-container
                    *ngTemplateOutlet="column.cellDef.template; context: {$implicit: item, card: true}"></ng-container>
                </ng-container>
              </div>
            </mat-card-header>
            <mat-card-content>
              <div *ngFor="let column of contentColumns" class="plate-content-item">
                <div class="content-item-title">{{column.title}}</div>
                <div class="content-item-text">
                  <ng-container
                    *ngTemplateOutlet="column.cellDef.template; context: {$implicit: item, card: true}"
                  ></ng-container>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </a>

        <div class="plate-header-menu">
          <ng-container *ngFor="let column of headerMenuColumns">
            <ng-container
              *ngTemplateOutlet="column.cellDef.template; context: {$implicit: item, card: true}"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <myflow-table-placeholders 
    *ngIf="!loaded" 
    [columns]="columns.toArray()"
    [instanceTable]="instanceTable"
    [isCard]="true"
  />
</ng-template>
