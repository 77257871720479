import { Store } from '@ngrx/store';
import { Organization } from 'app/entity/infrastructure';
import { MessageHandler } from 'app/shared/services/messages/entity-connection';
import { setOrganization } from 'app/store/actions/organization.actions';
import { State } from 'app/store/reducers';
import { Message } from '../sse/connection';

export class OrganizationEntityHandler implements MessageHandler<Organization> {
  constructor(
    private store: Store<State>,
  ) {}

  create(_msg: Message<Organization>): void {}

  delete(_msg: Message<Organization>): void {}

  update(msg: Message<Organization>): void {
    this.store.dispatch(setOrganization({ organization: msg.data }));
  }
}
