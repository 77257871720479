import { Device } from 'app/entity/mbm-devices';
import { Status } from 'app/shared/services/messages/entity-connection';
import { MessageOptions } from 'app/shared/services/messages/handler/message.handler';
import { ToastrService } from 'ngx-toastr';

export class MacBareMetalMessages implements MessageOptions<Device> {
  createMessageCallback(toastr: ToastrService, entity: Device): void {
    toastr.success(`<b>Device:</b> Succeeded to create ${entity.name}`, '', { enableHtml: true });
  }

  updateMessageCallback(_toastr: ToastrService, _entity: Device): void {
  }

  statusMessageCallback(_toastr: ToastrService, _id: number, _status: Status): void {
  }

  deleteMessageCallback(_toastr: ToastrService, _entity: Device): void {
  }
}
