import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocationDataService } from 'app/core/services/location/location.data';
import { Location } from 'app/entity/common';
import { Module } from 'app/entity/infrastructure';
import { Order } from 'app/entity/store';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { LayoutActions } from 'app/store/actions';
import { ServiceTemplate } from 'app/store/generic-store-infrastructure/service.template';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as fromLayout from 'app/store/reducers';
import { map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationService implements ServiceTemplate<Location> {
  readonly loading$ = new BehaviorSubject<boolean>(true);

  modules: Module[] = [];
  availableLocations: Location[] = [];

  private locations: Location[];

  constructor(
    private locationDataService: LocationDataService,
    private localStorageService: LocalStorageService,
    private store: Store<fromLayout.State>,
  ) {}

  getAll(_context?: number[]): Observable<Location[]> {
    return this.locationDataService.getAll([]).pipe(
      map(locations => locations.map(location => this.mapLocation(location))),
      tap(locations => this.localStorageService.setObject('locations', locations)),
      tap(locations => this.locations = locations),
      tap(() => this.store.dispatch(LayoutActions.setMenu({ menu: this.modules }))),
      tap(() => this.loading$.next(false)),
    );
  }

  moduleLocations(moduleId: number): Location[] {
    const list = this.locations && this.locations.length ? this.locations : this.localStorageService.getObject('locations');
    if (list && list.length) {
      return list
        .filter(location => location.availableModules.find(m => m.id === moduleId))
        .sort((a: Location, b: Location) => a.name.localeCompare(b.name));
    }
    return [];
  }

  setModules(modules: Module[]): void {
    this.modules = modules;
  }

  /**
   * @deprecated The method should not be used
   */
  create(_create: Location, _context?: number[]): Observable<Order | Location> {
    return of(null);
  }

  /**
   * @deprecated The method should not be used
   */
  delete(_id: number, _context?: number[]): Observable<void> {
    return of(null);
  }

  /**
   * @deprecated The method should not be used
   */
  getById(_id: number, _context?: number[]): Observable<Location> {
    return of(null);
  }

  /**
   * @deprecated The method should not be used
   */
  update(_id: number, _update: Location, _context?: number[]): Observable<Location> {
    return of(null);
  }

  private mapLocation(location: Location): Location {
    return {
      ...location,
      background: `/assets/img/${environment.brand}/regions/${location.key}.jpg`,
      // Getting Available Modules for each location
      availableModules: this.modules.filter(m => m.locations.find(l => l.id === location.id)),
    };
  }
}
