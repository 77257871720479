import { ElementRef } from '@angular/core';

export const autoPlayVideo = async (video: ElementRef): Promise<void> => {
  let success = false;

  while (!success) {
    const result = video.nativeElement.play();
    if (result) {
      result
        .then(() => success = true)
        .catch(() => {});
    } else {
      success = true;
    }

    await new Promise(r => setTimeout(r, 5000));
  }
};
