<div class="wizard side-popup">
  <div class="modal-header">
    <h1 class="modal-title">{{ title }}</h1>
    <div class="steps wizard-steps" *ngIf="stepsLength.length > 1">
      <div
        class="wizard-step"
        *ngFor="let step of stepsLength"
        [ngClass]="{'active': step <= (selectedIndex + 1)}"
      >
        {{ step }}
      </div>
    </div>
    <div class="wizard-close" (click)="close()">
      <i class="icon icon-cancel"></i>
    </div>
  </div>

  <div class="modal-body p-0" [class.no-center]="noCenter">
    <div class="row wizard-content m-0">
      <div class="wizard-section">
        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
      </div>
      <ng-content select="[summary]"></ng-content>
    </div>
  </div>

  <div class="modal-footer" [ngClass]="{'custom-footer': customFooter.childNodes.length !== 0}">
    <div class="wizard-footer-nav" *ngIf="customFooter.childNodes.length === 0">
      <button
        *ngIf="stepsLength.length > 1"
        class="wizard-footer-link previous-step"
        cdkStepperPrevious
        [ngClass]="{'not-active': selectedIndex === 0 || previousDisabled}"
        [disabled]="loading || previousDisabled"
      >
        Previous Step
      </button>
      <button class="wizard-footer-link" (click)="close()" [disabled]="loading">
        Cancel
      </button>
    </div>

    <div class="right-content" *ngIf="customFooter.childNodes.length === 0">
      <ng-content select="[amount]"></ng-content>

      <button
        cdkStepperNext
        class="btn btn-primary btn-next-step"
        [ngClass]="{'disabled': disabled}"
        *ngIf="_steps.length > (selectedIndex + 1)"
        [disabled]="disabled"
      >
        Next Step
      </button>
      <app-submit-btn (btnClick)="save()" [text]="btnSubmitText" [loading]="loading" [disabled]="disabled"
                      *ngIf="_steps.length === (selectedIndex + 1)"></app-submit-btn>
    </div>

    <div #customFooter class="right-content" [ngClass]="{'d-none': customFooter.childNodes.length === 0}">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>

