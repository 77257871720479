import { Product } from '../common';
import { ObjectStorageCredentials } from './credentials.model';
import { Location } from 'app/entity/common';

export interface ObjectStorageInstance {
  id: number;
  name: string;
  location: Location;
  product: Product;
  credentials: ObjectStorageCredentials | null;
  size?: number;
};

export interface ObjectStorageCreate {
  name: string;
  locationId: number;
};
