import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { lastValueFrom, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { Organization } from 'app/entity/infrastructure/organization.model';
import { User } from 'app/entity/infrastructure/user.model';

import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';

import { ApiService } from 'app/shared/services/api/api.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

import {
  httpContextSwitcher,
  httpLogin,
  httpLogout,
  httpPasswordChange,
} from 'app/shared/utils/http.helper';
import { AuthActions } from 'app/store/actions';

export interface ChangePasswordRequestBody {
  currentPassword: string;
  newPassword: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  redirectUrl: string;

  private isImpersonate: boolean;
  private userValue: User;
  private organizationValue: Organization;

  constructor(
    private apiService: ApiService,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
  ) {
    this.setMainCreds();

    this.store.select(fromRoot.getImpersonated)
      .pipe(
        tap(isImpersonate => this.isImpersonate = !!isImpersonate),
      )
      .subscribe();
  }

  get userId(): number {
    return this.userValue.id;
  }

  get organizationId(): number {
    return this.organizationValue.id;
  }

  /**
   * @param credentials
   * @argument username
   * @argument password
   */
  authenticate(credentials: any): Observable<any> {
    return this.apiService.post(httpLogin(), credentials);
  }

  logout(returnUrl?: string): void {
    this.route.queryParamMap.pipe(
      take(1),
    ).subscribe((params) => {
      this.localStorage.clearSessionStorage();
      if (!params.get('returnUrl')) {
        this.router.navigate([ '/login' ], { queryParams: { returnUrl: returnUrl || this.router.url } });
      } else {
        this.router.navigate([ this.router.url ]);
      }
    });
  }

  postLogout(): Promise<void> {
    return lastValueFrom(this.apiService.post(httpLogout()));
  }

  changePassword(data: ChangePasswordRequestBody): Observable<any> {
    return this.apiService.post(httpPasswordChange(), data);
  }

  fetchContextToken(data: { organizationId?: number; projectId?: number }): Promise<any> {
    return lastValueFrom(this.apiService.post(httpContextSwitcher(), data))
      .then((user: User) => {
        if (!this.isImpersonate) {
          this.localStorage.setObject('user', user);
        }
        this.store.dispatch(AuthActions.loginSuccess({ user }));
      });
  }

  private setMainCreds(): void {
    this.store.select(fromRoot.getUser).pipe(
      map(user => this.userValue = user),
      switchMap(() => this.store.select(fromRoot.getOrganization)),
      map(org => org || (this.userValue ? this.userValue.defaultOrganization : null)),
      map(org => this.organizationValue = org),
    ).subscribe();
  }
}
