import { Store } from '@ngrx/store';
import { Balance } from 'app/entity/billing/balance.model';
import { MessageHandler } from 'app/shared/services/messages/entity-connection';
import { getBalanceSuccess } from 'app/store/actions/organization.actions';
import { State } from 'app/store/reducers';
import { Message } from 'app/shared/services/sse/connection';

export class BalanceEntityHandler implements MessageHandler<Balance> {
  constructor(
    private store: Store<State>,
  ) {}

  create(_msg: Message<Balance>): void {
  }

  update(msg: Message<Balance>): void {
    this.store.dispatch(getBalanceSuccess({ balance: msg.data }));
  }

  delete(_msg: Message<Balance>): void {
  }
}
