import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Observable, Subject, forkJoin, lastValueFrom } from 'rxjs';
import { takeUntil, tap, first, delay } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { UserActions } from 'app/store/actions';
import * as fromRoot from 'app/store/reducers';

import { ToastrService } from 'ngx-toastr';

import { AvatarService } from 'app/shared/services/avatar.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

import { OrganizationUserService as AdminOrganizationUserService } from 'app/admin/store/organization-user';
import { AddUserRequestBody, EditUserRequestBody, OrganizationService } from 'app/organization/services/organization';

import { EditProfileFormService } from '../../services/edit-profile-form/edit-profile-form.service';
import { SidePopupService } from '../../services/side-popup.service';
import { DynamicFormComponent } from '../dynamic-form/containers/dynamic-form/dynamic-form.component';
import { contextOrganization } from 'app/admin/store/context/organization-quota.context';
import { User } from 'app/entity/infrastructure';
import { UserService as AdminUserService } from 'app/admin/store/user';

@Component({
  selector: 'myflow-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: [ './edit-profile.component.scss' ],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  @ViewChild('file') file: any;
  @ViewChild('userForm') userForm: DynamicFormComponent;
  @ViewChild('imageCrop', { static: true }) imageCrop: TemplateRef<any>;
  @Input() orgId: number; // for Admin
  @Input() isAdminForm: boolean; // for Admin
  @Input() id$: Observable<number>;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  elements: any;
  avatar: any;
  imgFile: any;
  id: number;
  editMode = true;

  isOwnUser = false;
  isLoading = false;
  draggable = false;
  isAdmin = false;

  private destroyed$ = new Subject();

  constructor(
    private organizationService: OrganizationService,
    private adminOrganizationUserService: AdminOrganizationUserService,
    private adminUserService: AdminUserService,
    private formService: EditProfileFormService,
    private localStore: LocalStorageService,
    private avatarService: AvatarService,
    private toastrService: ToastrService,
    private sidePopup: SidePopupService,
    private store: Store<fromRoot.State>,
  ) { }

  @HostListener('dragover', [ '$event' ]) onDragOver(evt: DragEvent): void {
    this.draggable = true;
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', [ '$event' ])
  @HostListener('dragleave', [ '$event' ]) onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.draggable = false;
  }

  ngOnInit(): void {
    if (this.id$) {
      this.id$.pipe(
        takeUntil(this.destroyed$),
        tap((id: number) => {
          this.isAdmin = (!!this.orgId || this.isAdminForm);
          if (id) {
            this.editMode = true;
            this.id = id;
          } else {
            this.editMode = false;
          }

          this.getCurrentUserData();
        }),
      ).subscribe();
    }

    if (!this.id) {
      this.id = this.getCurrentUserId();
      this.getCurrentUserData();
    }
  }

  fetchUser(): void {
    // @todo with store?
    forkJoin([
      this.organizationService.fetchUserOrganizations(),
      this.organizationService.fetchUser(this.id),
    ]).pipe(
      // Observable needs to be delay as this.userForm is at this point not yet
      // initialized/set (because it was blocked by an *ngIf in the parent up until this point)
      delay(200),
      first(),
      tap(results => {
        const [ organization, user ] = results;
        this.fetchUserAvatar();

        this.userForm.patchValue(user);

        if (this.isOwnUser) {
          this.userForm.addControl({
            type: 'select',
            options: {
              items: organization,
              bindLabel: 'name',
              bindValue: 'id',
            },
            name: 'defaultOrganization',
            value: user && user.defaultOrganization ? user.defaultOrganization.id : null,
            label: 'Default Organization',
          });
        }
      }),
      tap(this.subscribeToFormChange),
    ).subscribe();
  }

  fetchUserAdmin(): void {
    let userFetch: Observable<User>;

    if (this.orgId) {
      userFetch = this.adminOrganizationUserService.getById(this.id, contextOrganization(this.orgId));
    } else {
      userFetch = this.adminUserService.getById(this.id);
    }

    userFetch.pipe(
      // Observable needs to be delay as this.userForm is at this point not yet
      // initialized/set (because it was blocked by an *ngIf in the parent up until this point)
      delay(200),
      first(),
      tap((data) => {
        this.userForm.patchValue(data);

        if (this.isOwnUser) {
          this.userForm.addControl({
            type: 'select',
            options: {
              items: [ data.defaultOrganization ],
              bindLabel: 'name',
            },
            name: 'defaultOrganization',
            value: data && data.defaultOrganization ? data.defaultOrganization.id : null,
            label: 'Default Organization',
          });
        }

      }),
      tap(this.subscribeToFormChange),
    ).subscribe();
  }

  subscribeToFormChange(): void {
    if (!this.userForm) {
      return;
    }

    this.userForm.formChange.subscribe(() => {
      this.sidePopup.getCurrent().updated.next(true);
    });
  }

  onSubmit(): void {
    this.userForm.form.markAllAsTouched();

    if (!this.userForm.valid) {
      return;
    }

    this.isLoading = true;

    if (this.editMode) {
      this.updateUser(this.userForm.value);
    } else {
      this.createUser(this.userForm.value);
    }
  }

  createUser(user: AddUserRequestBody): void {
    if (this.isAdmin) {
      lastValueFrom(
        this.adminOrganizationUserService.create(user, contextOrganization(this.orgId)),
      )
      .then(() => {
        this.onSaveComplete();
      })
      .catch(() => this.isLoading = false);
      return;
    }

    lastValueFrom(
      this.organizationService.addUser(user),
    )
    .then(() => {
      this.onSaveComplete();
    })
    .catch(() => this.isLoading = false);
  }

  updateUser(user: EditUserRequestBody & { username: string }): void {
    if (user && user.username) {
      delete user.username;
    }

    if (this.isAdmin) {
      let updateUser: Observable<User>;
      if (this.orgId) {
        updateUser = this.adminOrganizationUserService.update(this.id, user, contextOrganization(this.orgId));
      } else {
        updateUser = this.adminUserService.update(this.id, user);
      }

      lastValueFrom(
        updateUser,
      ).then(() => {
        this.onSaveComplete(this.id);
      })
      .catch(() => this.isLoading = false);
      return;
    }

    lastValueFrom(
      this.organizationService.updateUser(user, this.id),
    ).then((updatedUser: User) => {
      const currentUser = this.localStore.getObject('user');

      // Cleanup responsed user object because response data wrong in this cases:
      for (const key of Object.keys(updatedUser)) {
        if (key === 'assignedOrganizations' || key === 'defaultOrganization') {
          delete updatedUser[key];
        }
      }

      const newUser = { ...currentUser, ...updatedUser };

      if (currentUser.id === newUser.id) {
        this.store.dispatch(UserActions.setUserProfile({ user: newUser }));
        this.localStore.setObject('user', newUser);
      }

      this.onSaveComplete(+updatedUser.id);
    })
    .catch(() => this.isLoading = false);
  }

  /** @todo place to shared? */
  fetchUserAvatar(): void {
    lastValueFrom(
      this.avatarService.fetchAvatar(this.id),
    ).then((response: any) => {
      const blob = new Blob([ response.body ], { type: `${response.body.type}` });
      if (this.isOwnUser) {
        this.avatarService.setAvatarImgBlob(blob);
      }
      this.avatar = URL.createObjectURL(blob);
    });
  }

  open(fileEvent?: any): void {
    this.imgFile = fileEvent ? fileEvent[0] : this.file.nativeElement.files[0];
    if (this.imgFile) {
      this.sidePopup.open({ custom: this.imageCrop }, true)
        .subscribe(() => null);
    }
  }

  closeImgCrop(data: unknown): void {
    if (data) {
      this.imgFile = null;
      this.sidePopup.close();
      this.fetchUserAvatar();
    }
  }

  deleteAvatar(): void {
    lastValueFrom(
      this.avatarService.deleteAvatar(this.id),
    ).then(() => {
      this.fetchUserAvatar();
      this.toastrService.success('Avatar deleted');
    })
    .catch((error: any) => {
      this.toastrService.error(error.error.error.message.en);
    });
  }

  close(): void {
    this.imgFile = null;
    this.closePopup.emit({ close: true, update: false });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private onSaveComplete(id?: number): void {
    const message = id ? 'User updated' : 'User created';
    this.toastrService.success(message);
    this.isLoading = false;
    this.closePopup.emit({ close: true, update: true });
  }

  private getCurrentUserId(): number {
    const xIdentity = this.localStore.getSessionObject('impersonated-user'); // for impersonate feature (multiple users)
    if (xIdentity) {
      return +xIdentity.id;
    } {
      const currentUser = this.localStore.getObject('user');
      return +currentUser.id;
    }
  }

  private getCurrentUserData(): void {
    const currentUserId = this.getCurrentUserId();
    this.isOwnUser = currentUserId === this.id;
    this.elements = this.formService.userControls(this.editMode);

    if (this.editMode) {
      if (this.isAdmin) {
        this.fetchUserAdmin();
      } else {
        this.fetchUser();
      }
      if (this.userForm) {
        this.userForm.form.controls.username.disable();
      }
    } else if (this.userForm) {
      this.userForm.form.controls.username.enable();
    }
  }
}
