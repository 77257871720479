import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number | string, currencySymbol: string, nonstandardFormat: boolean = false, decimalDigits: number = 2): string {
    // Format the number by seperating the digits in 3 pairs (e.g. 5000 => 5'000, but 5000.123456 => 5'000.123456)
    const parts = Number(value).toFixed(decimalDigits).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    const result = parts.join('.');

    return nonstandardFormat ? `${currencySymbol} ${result}` : `${result} ${currencySymbol}`;
  }
}
