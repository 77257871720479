/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, PipeTransform } from '@angular/core';

export type ByteUnit = 'B' | 'kB' | 'KB' | 'MB' | 'GB' | 'TB';

@Pipe({
  name: 'bytes',
})
export class BytesPipe implements PipeTransform {
  static formats: { [key: string]: { max: number; prev?: ByteUnit } } = {
    B: { max: 1000 ** 1 },
    kB: { max: 1000 ** 2, prev: 'B' },
    KB: { max: 1000 ** 2, prev: 'B' }, // Backward compatible
    MB: { max: 1000 ** 3, prev: 'kB' },
    GB: { max: 1000 ** 4, prev: 'MB' },
    TB: { max: Number.MAX_SAFE_INTEGER, prev: 'GB' },
  };

  static formatResult(result: number, unit: ByteUnit): string {
    return `${result} ${unit}`;
  }

  static calculateResult(format: { max: number; prev?: ByteUnit }, bytes: number): number {
    const prev = format.prev ? BytesPipe.formats[format.prev] : undefined;
    return prev ? bytes / prev.max : bytes;
  }

  transform(input: number, decimal: number = 0, from: ByteUnit = 'B', to: ByteUnit|null = null): string {
    const isValidInput = Number.isFinite(input) && Number.isFinite(decimal) && Number.isInteger(decimal) && decimal >= 0;
    if (!isValidInput) {
      return input.toString();
    }

    const bytes = (input * BytesPipe.formats[from].max) / 1000;

    if (to) {
      const format = BytesPipe.formats[to];
      const result = this.toDecimal(BytesPipe.calculateResult(format, bytes), decimal);

      return BytesPipe.formatResult(result, to);
    }

    for (const key in BytesPipe.formats) {
      const format = BytesPipe.formats[key];

      if (format && bytes < format.max) {
        const result = this.toDecimal(BytesPipe.calculateResult(format, bytes), decimal);

        return BytesPipe.formatResult(result, key as ByteUnit);
      }
    }

    return null;
  }

  toDecimal(value: number, decimal: number): number {
    return +value.toFixed(decimal);
  }
}
