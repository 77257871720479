import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AuthenticationService } from 'app/core/services/authentication.service';
import { User } from 'app/entity/infrastructure/user.model';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { LocationService } from 'app/core/services/location';

import { AuthActions, UserActions } from '../actions';
import * as fromRoot from '../reducers';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login.type),
    map((action: any) => action.user),
    map((user: User) => {
      this.localStorage.clearSessionStorage();
      this.localStorage.setObject('user', user);

      return AuthActions.loginSuccess({ user });
    }),
    catchError(error => of(AuthActions.loginFailure({ error }))),
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginSuccess.type),
    map((action: any) => action.user),
    tap(user => this.store.dispatch(UserActions.setUser({ user }))),
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout.type),
    map((action: any) => action ? action.returnUrl : null),
    map((returnUrl: string) => this.authService.logout(returnUrl)),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private localStorage: LocalStorageService,
    private locationService: LocationService,
    private store: Store<fromRoot.State>,
  ) {
  }
}
