import { Store } from '@ngrx/store';
import { InvokeAction } from 'app/entity/common';
import { BaseEntity } from 'app/entity/store/base-entity';
import { DummyRecord } from 'app/entity/store/dummy-record';
import { Order } from 'app/entity/store/order';
import { OrderMessagesService } from 'app/shared/services/messages/order-messages.service';
import { EntityCache } from 'app/store/entity-cache/entity-cache';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { GenericStore } from 'app/store/generic-store-infrastructure/generic.store';
import { ServiceTemplate } from 'app/store/generic-store-infrastructure/service.template';
import { filterEntity, StoreEntity } from 'app/store/generic-store-infrastructure/store.entity';
import { BehaviorSubject, combineLatest, iif, merge, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { first, map, mergeMap, mergeWith, switchMap, tap } from 'rxjs/operators';

export class GenericService<T extends BaseEntity, Create = T, Update = T, Mapping = T> implements ServiceTemplate<T, Create, Update> {
  protected entityName: string;
  protected store: GenericStore<T>;

  protected loadedAllEntities = false;

  protected context$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  protected filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  protected loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected useAsyncDummyRecordCreation = false;

  protected rerenderActivatedId = null;

  constructor(
    entityName: string,
    store: Store<EntityCache>,
    protected dataService: GenericDataService<T, Create, Update>,
    protected orderMessagesService?: OrderMessagesService,
  ) {
    this.entityName = entityName;

    this.store = new GenericStore<T>(this.entityName, store);
  }

  get storeEntities$(): Observable<StoreEntity<T>[]> {
    return this.store.loadAllRaw();
  }

  get entities$(): Observable<T[]> {
    return this.storeEntities$.pipe(
      map(storeEntities => storeEntities.map(storeEntity => storeEntity.entity)),
    );
  }

  get rerenderActiveId$(): number {
    return this.rerenderActivatedId;
  }

  get filteredEntities$(): Observable<Mapping[]> {
    return combineLatest(
      [
        this.storeEntities$,
        this.context$,
        this.filter$,
      ],
    ).pipe(
      map(([ entities, context, filter ]): [StoreEntity<T>[], string] => [
        entities.filter(storeEntity => GenericService.compareContext(storeEntity.context, context)),
        filter,
      ]),
      map(([ entities, filter ]) => [
        entities.map(entity => this.mapEntity(entity.entity)),
        filter,
      ]),
      map(([ entities, filter ]) => (
        (entities as Mapping[]).filter(entity => filterEntity(entity as Record<string, unknown>, filter as string))
      )),
    );
  }

  get keys$(): Observable<number[]> {
    return this.store.loadKeys();
  }

  get loaded$(): Observable<boolean> {
    return this.loading$.asObservable().pipe(
      map(value => !value),
    );
  }

  setContext(context: number[]): void {
    this.context$.next(context);
  }

  getContext(): number[] {
    return this.context$.getValue();
  }

  setFilter(filter: string): void {
    this.filter$.next(filter);
  }

  setRerenderActiveId(activeId: number): void {
    this.rerenderActivatedId = activeId;
  }

  getFilter(): string {
    return this.filter$.getValue();
  }

  create(create: Create, context?: number[]): Observable<T> {
    const ctx = context || this.getContext();
    return this.dataService.create(ctx, create).pipe(
      switchMap(
        (entity) => iif(
          () => !!(this.orderMessagesService && entity as Order && (entity as Order).ref),
          // process order
          iif(() => this.useAsyncDummyRecordCreation, this.createDummyRecordAsync(create), of(this.createDummyRecord(create))).pipe(
            map(dummyRecordEntity => {
              const order = entity as Order;
              const dummyRecord: DummyRecord<T> = {
                ...dummyRecordEntity,
                orderRef: order.ref,
              };

              this.orderMessagesService.waitForRecordToCreate(
                this.entityName,
                this.getNameFromEntity(dummyRecordEntity),
                order.ref,
              ).then(result => {
                if (result) {
                  this.store.delete(+dummyRecordEntity.id);
                }
              });

              return dummyRecord;
            }),
          ),
          // continue with entity
          of(entity as T),
        ),
      ),
      tap(entity => this.store.addOne(ctx, entity)),
    );
  }

  getById(id: number, context?: number[]): Observable<T> {
    this.loading$.next(true);

    const ctx = context || this.getContext();

    const storeGetId = this.store.load(id);
    const dataGetId = this.dataService.getById(ctx, id).pipe(
      tap(entity => this.store.addOne(ctx, entity)),
      tap(() => this.loading$.next(false)),
    );

    return this.keys$.pipe(
      mergeMap(keys =>
        iif(() =>
          keys.includes(id),
          storeGetId.pipe(
            mergeWith(dataGetId),
          ),
          dataGetId.pipe(
            switchMap(() => storeGetId),
          ),
        ),
      ),
      tap(data => {
        if (data) {
          this.loading$.next(false);
        }
      }),
    );
  }

  getAll(context?: number[], forceReload: boolean = false): Observable<T[]> {
    this.loading$.next(true);

    const ctx = context || this.getContext();

    const storeGetAll = this.store.loadAllRaw().pipe(
      map(entities => entities.filter(entity => GenericService.compareContext(entity.context, ctx))),
      map(entities => entities.map(entity => entity.entity)),
    );
    const dataGetAll = this.dataService.getAll(ctx).pipe(
      tap(() => {
        if (this.loadedAllEntities) {
          this.clearContext(ctx).subscribe();
        }
      }),
      tap(entities => this.store.addMany(ctx, entities)),
      tap(() => this.loading$.next(false)),
    );

    if (!this.loadedAllEntities || forceReload) {
      return dataGetAll.pipe(
        tap(() => this.loadedAllEntities = true),
        switchMap(() => storeGetAll),
      );
    }

    return merge(
      storeGetAll,
      dataGetAll,
    ).pipe(
      tap(data => {
        if (data.length > 0) {
          this.loading$.next(false);
        }
      }),
    );
  }

  update(id: number, update: Update, context?: number[]): Observable<T> {
    const ctx = context || this.getContext();
    return this.dataService.update(ctx, id, update).pipe(
      tap(entity => this.store.update(ctx, entity)),
    );
  }

  invokeAction(id: number, action: InvokeAction, context?: number[]): Observable<T> {
    const ctx = context || this.getContext();

    return this.dataService.invokeAction(ctx, id, action).pipe(
      tap(entity => this.updateOneInCache(ctx, entity)),
    );
  }

  delete(id: number, context?: number[]): Observable<void> {
    const ctx = context || this.getContext();
    return this.dataService.delete(ctx, id).pipe(
      tap(() => this.store.delete(id)),
    );
  }

  clearContext(context: number[]): Observable<number[]> {
    return this.storeEntities$.pipe(
      first(),
      // get all entities of same context
      map(entities => entities.filter(entity => {
        const entityContext = entity.context;
        if (entityContext.length !== context.length) {
          return false;
        }

        for (let i = 0; i < context.length; i++) {
          if (entityContext[i] !== context[i]) {
            return false;
          }
        }

        return true;
      })),
      // remove all dummy records
      map(entities => entities.filter(entity =>
        !(entity.entity as DummyRecord<T> && (entity.entity as DummyRecord<T>).orderRef),
      )),
      map(entities => entities.map(entity => entity.id)),
      tap(ids => this.removeMultipleFromCache(ids)),
    );
  }

  getAllFromCache(): Observable<T[]> {
    return this.store.loadAll();
  }

  getOneStoreEntityFromCache(id: number): Observable<StoreEntity<T>> {
    return this.store.loadRaw(id);
  }

  getOneFromCache(id: number): Observable<T> {
    return this.store.load(id);
  }

  addOneToCache(context: number[], entity: T): void {
    this.store.addOne(context, entity);
  }

  addMultipleToCache(context: any[], entities: T[]): void {
    this.store.addMany(context, entities);
  }

  updateOneInCache(context: number[], entity: T): void {
    this.store.update(context, entity);
  }

  removeOneFromCache(id: number): void {
    this.store.delete(id);
  }

  removeMultipleFromCache(ids: number[]): void {
    this.store.deleteMultiple(ids);
  }

  cleanUpCache(): void {
    this.store.deleteAll();
  }

  getContextSnapshot(): number[] {
    return this.context$.getValue();
  }

  protected createDummyRecord(_create: Create): T {
    return;
  }

  protected createDummyRecordAsync(_create: Create): Observable<T> {
    return;
  }

  protected mapEntity(entity: T): Mapping {
    return entity as unknown as Mapping;
  }

  protected getNameFromEntity(entity: T): string {
    return (entity as any).name;
  }

  private static compareContext(context1: number[], context2: number[]): boolean {
    if (!context1 || !context2 || context1.length !== context2.length) {
      return false;
    }

    for (let i = 0; i < context1.length; i++) {
      if (context1[i] !== context2[i]) {
        return false;
      }
    }

    return true;
  }
}
