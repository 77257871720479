import { Injectable } from '@angular/core';
import { CreateImage, Image } from 'app/entity/ci-engine';
import { ApiService } from 'app/shared/services/api';
import {
  httpCiEngineSubscriptionImages,
} from 'app/shared/utils/endpoints/ci-engine.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionImageDataService extends GenericDataService<Image, CreateImage> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpCiEngineSubscriptionImages, apiService);
  }
}
