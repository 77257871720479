import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-code-fields',
  templateUrl: './code-field.component.html',
  styleUrls: [ './code-field.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => CodeFieldsComponent),
      multi: true,
    },
  ],
})
export class CodeFieldsComponent implements ControlValueAccessor, AfterViewInit, OnInit, OnChanges {
  @Input() size = 4;
  @ViewChildren('step', { read: ElementRef }) steps: QueryList<ElementRef>;
  inputControls: unknown[] = Array.from({ length: this.size });

  @HostListener('paste', [ '$event' ]) onEvent($event: ClipboardEvent): void {
    $event.preventDefault(); // blocks write to input element
    const data = $event.clipboardData.getData('text').replace(/[^0-9]/g, ''); // left just numbers

    this.steps.toArray().forEach((step, index) => step.nativeElement.value = data[index] ?? '');

    setTimeout(() => {
      this.steps.first.nativeElement.focus();
    }, 100);
  }

  ngOnInit(): void {
    this.inputControls = Array.from({ length: this.size });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      this.inputControls = Array.from({ length: this.size });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.steps.first.nativeElement.focus();
    }, 0);
  }

  writeValue(_value: unknown): void {
  }

  propagateChange = (_value: unknown): void => {};

  registerOnChange(fn: (_: unknown) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  onInput(step: number, event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    const value = +target.value.length || null;

    if (value && value <= 9) {
      const nextStep = this.steps.toArray()[++step];
      if (step !== this.steps.length) {
        nextStep.nativeElement.focus();
      }
    } else if (!value) {
      this.backSpace(step);
    }

    this.rebuildValue();
  }

  private backSpace(step: number): void {
    const prevStep = this.steps.toArray()[--step];
    if (step >= 0) {
      prevStep.nativeElement.focus();
    }
  }

  private rebuildValue(): void {
    const value = this.steps.reduce((result, step) => result + (step.nativeElement as HTMLInputElement).value, '');

    this.propagateChange(value);
  }
}
