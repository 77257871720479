import { Component, Input, OnInit } from '@angular/core';

import { Location } from 'app/entity/common';
import { ValueAccessorBase } from 'app/shared/utils/value-accessor';
import { environment } from 'environments/environment';

interface Region extends Location {
  background: string;
}

@Component({
  selector: 'myflow-region-select',
  templateUrl: './region-select.component.html',
  styleUrls: [ './region-select.component.scss' ],
  providers: [
    // eslint-disable-next-line no-use-before-define
    ValueAccessorBase.createProviderFor(RegionSelectComponent),
  ],
})
export class RegionSelectComponent extends ValueAccessorBase<Location> implements OnInit {
  @Input() locations: Location[];
  @Input() horizontal: boolean;

  regions: Region[];

  ngOnInit(): void {
    this.regions = this.locations.map(location => ({
      ...location,
      background: `/assets/img/${environment.brand}/regions/${location.key}.jpg`,
    }));
  }

  getRegionBackgroundStyle(region: Region): any {
    return {
      backgroundImage: `url('${region.background}')`,
    };
  }

  getRegionFacts(region: Region): string[] {
    switch (region.key) {
      case 'key-alp1':
        return [
          'Standard Data Center',
          'Renewable Energy 100%',
        ];
      case 'key-alp2':
        return [
          'Bunker Data Center',
          'Renewable Energy 100%',
          'CO2 Neutral',
        ];
      case 'key-zrh1':
        return [
          'Standard Data Center',
          'Renewable Energy 65%',
        ];
      case 'key-bit1':
        return [
          'Standard Data Center',
        ];
      default:
        return [];
    }
  }

  isNew(region: Region): boolean {
    return region.key === 'key-alp2';
  }
}
