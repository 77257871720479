export const environment = {
  production: false,
  apiHost: 'https://api.dev.cloudbit.ch/',
  hubHost: 'https://hub.dev.cloudbit.ch/.well-known/mercure',
  statusHost: 'https://status.cloudbit.ch/',
  termsAndConditions: 'https://cloudbit.ch/gtc',
  documentation: 'https://doc.cloudbit.ch/',
  basicHost: 'cloudbit.ch',
  stripeKey: 'pk_test_SjnK4LhcZPp6XnbZHSel1ZwD003vRKoAib',
  supportEmail: 'support@d3v-flowswiss.zendesk.com',
  brand: 'cloudbit',
  rechargeValues: {
    cardMin: 50,
    cardMax: 10000,
    cardSteps: [
      {
        amount: 50,
        cashbackPercent: 0,
      },
      {
        amount: 200,
        cashbackPercent: 0,
      },
      {
        amount: 500,
        cashbackPercent: 1,
      },
      {
        amount: 1000,
        cashbackPercent: 2,
      },
      {
        amount: 2500,
        cashbackPercent: 2,
      },
      {
        amount: 5000,
        cashbackPercent: 3,
      },
      {
        amount: 10000,
        cashbackPercent: 5,
      },
    ],
    invoiceMin: 500,
    invoiceMax: 50000,
    invoiceSteps: [
      {
        amount: 500,
        cashbackPercent: 1,
      },
      {
        amount: 1000,
        cashbackPercent: 2,
      },
      {
        amount: 2500,
        cashbackPercent: 3,
      },
      {
        amount: 5000,
        cashbackPercent: 4,
      },
      {
        amount: 10000,
        cashbackPercent: 5,
      },
      {
        amount: 20000,
        cashbackPercent: 7.5,
      },
      {
        amount: 50000,
        cashbackPercent: 10,
      },
    ],
  },
  cluster: {
    masterId: 46,
    masterGen2Id: 132,
    products: {
      max: 10,
      min: 3,
    },
  },
  status: {
    statusPage: 'https://status.cloudbit.ch',
    baseUrl: 'https://public-api.freshstatus.io/v1/',
    accountId: '38738',
    updateInterval: '120',
  },
  objectStorage: {
    baseStorage: 250, // in GB
    additionalGbCost: 0.02,
    additionalGbCostGen2: 0.022,
    endpoints: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BIT1: 'https://stage-s3-console-vip.alp1.flow.swiss/#endpoint=',
    },
  },
  ciEngine: {
    additionalConcurrencyEssentialCost: 130,
    additionalConcurrencyEnterpriseCost: 350,
  },
};
